/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const calculateRisk = /* GraphQL */ `
  mutation CalculateRisk($responses: [Response]) {
    calculateRisk(responses: $responses) {
      rating
      __typename
    }
  }
`;
